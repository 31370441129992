import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomInput from '../../common/CustomInput/CustomInput';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { Icon } from '../../common/Icon/Icon';
import Message from '../../common/Message/Message';
import Pager from '../../common/Pager/Pager';
import ToggleSelect from '../../common/ToggleSelect/ToggleSelect';
import { HEADER_HEIGHT } from '../../core/constants';
import { useDataContext } from '../../core/context/data.context';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import { Member, Tag } from '../../core/models';
import './Community.css';
import CommunityItem from './CommunityItem/CommunityItem';
import CommunityMap from './CommunityMap/CommunityMap';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const TABS = [
  {
    name: 'List view',
    option: 'list',
    icon: <Icon.List />,
  },
  {
    name: 'Map view',
    option: 'map',
    icon: <Icon.Location />,
  },
];

function Community() {
  const [showMap, setShowMap] = useState(false);
  const { isMobile } = useScreenSizeContext();
  const [initialRender, setInitialRender] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = useMemo(() => searchParams.get('page') || '1', [searchParams]);
  const [hotMarker, setHotMarker] = useState<string | null>(null);
  const expertises = useMemo(() => searchParams.get('expertises') || '', [searchParams]);
  const roles = useMemo(() => searchParams.get('roles') || '', [searchParams]);
  const classes = useMemo(() => searchParams.get('classes') || '', [searchParams]);
  const industries = useMemo(() => searchParams.get('industries') || '', [searchParams]);
  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);
  const [showFilters, setShowFilters] = useState<boolean>(
    !!(expertises || roles || classes || industries),
  );
  const search = useFormInput(searchValue);
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/users/members?per_page=12&source=platform&page=${page}${
      !searchValue
        ? ''
        : `&current_location.city=${searchValue}&name=${searchValue}&company.name=${searchValue}&title=${searchValue}&pronouns=${searchValue}`
    }${!expertises ? '' : `&expertises=${expertises}`}${!roles ? '' : `&community_roles=${roles}`}${
      !classes ? '' : `&member_classes=${classes}`
    }${!industries ? '' : `&industries=${industries}`}`,
  );
  const { result: fetchedExpertises, doFetch: doExpertisesFetch } = useFetch(
    `${BASE_URL}/expertises/published?per_page=100`,
  );
  const { result: fetchedCommunityRoles, doFetch: doCommunityRolesFetch } = useFetch(
    `${BASE_URL}/community_roles/published?per_page=100`,
  );
  const { result: fetchedMemberClasses, doFetch: doMemberClassesFetch } = useFetch(
    `${BASE_URL}/member_classes/published?per_page=100`,
  );
  const { result: fetchedIndustries, doFetch: doIndustriesFetch } = useFetch(
    `${BASE_URL}/industries/published?per_page=100`,
  );
  const { result: fetchedTotal, doFetch: doTotalFetch } = useFetch(
    `${BASE_URL}/users/members/total`,
  );

  const {
    data: { community, generalData },
    setPage,
  } = useDataContext();

  useEffect(() => {
    setPage('community');
  }, [setPage]);

  useEffect(() => {
    doExpertisesFetch();
    doCommunityRolesFetch();
    doMemberClassesFetch();
    doIndustriesFetch();
    doTotalFetch();
  }, [
    doCommunityRolesFetch,
    doExpertisesFetch,
    doIndustriesFetch,
    doMemberClassesFetch,
    doTotalFetch,
  ]);

  useEffect(() => {
    setInitialRender(false);
  }, []);

  useEffect(() => {
    doFetch();
  }, [searchValue, page, expertises, roles, classes, industries, doFetch]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (initialRender) return;

    searchParams.delete('page');
    timeout.current = setTimeout(() => {
      if (search.value) {
        searchParams.set('search', search.value);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, 500);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.value]);

  const handleFilterBtnClick = useCallback(() => {
    setShowFilters((prevState) => !prevState);
  }, []);

  const expertiseOptions = useMemo(
    () =>
      !fetchedExpertises?.data
        ? []
        : fetchedExpertises.data.items.map((el: Tag) => ({ value: el.slug, label: el.name })),
    [fetchedExpertises],
  );

  const rolesOptions = useMemo(
    () =>
      !fetchedCommunityRoles?.data
        ? []
        : fetchedCommunityRoles.data.items.map((el: Tag) => ({ value: el.slug, label: el.name })),
    [fetchedCommunityRoles],
  );

  const classesOptions = useMemo(
    () =>
      !fetchedMemberClasses?.data
        ? []
        : fetchedMemberClasses.data.items.map((el: Tag) => ({ value: el.slug, label: el.name })),
    [fetchedMemberClasses],
  );

  const industriesOptions = useMemo(
    () =>
      !fetchedIndustries?.data
        ? []
        : fetchedIndustries.data.items.map((el: Tag) => ({ value: el.slug, label: el.name })),
    [fetchedIndustries],
  );

  const handleFilters = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      searchParams.delete('page');
      if (e.currentTarget.value) {
        searchParams.set(e.currentTarget.name, e.currentTarget.value);
      } else {
        searchParams.delete(e.currentTarget.name);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    const calculateHeaderPinned = () => {
      if (!headerRef.current) return;
      headerRef.current.classList.toggle(
        'is-pinned',
        headerRef.current.getBoundingClientRect().top <= HEADER_HEIGHT,
      );
    };

    document.addEventListener('scroll', calculateHeaderPinned, { passive: true });
    return () => {
      document.removeEventListener('scroll', calculateHeaderPinned);
    };
  }, []);

  const handleTabChange = useCallback((e: string) => {
    setShowMap(e === 'map');
  }, []);

  return (
    <>
      <Helmet>
        {!community?.seo?.title && !generalData?.seo?.title ? null : (
          <title>{community?.seo?.title || generalData?.seo?.title}</title>
        )}
        {!community?.seo?.canonical ? null : (
          <link rel="canonical" href={community?.seo?.canonical} />
        )}
        {!community?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={community?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      <section className="community">
        <div className="wrap">
          <div className="community__container gap-lg">
            <div className="community__lister flex gap-sm">
              <div className="community__header flex gap-md" ref={headerRef}>
                <h1 className="community__title h1">
                  Community{' '}
                  {!fetchedTotal?.total ? null : (
                    <>
                      of <span className="community__title-highlight">{fetchedTotal?.total}</span>
                    </>
                  )}
                </h1>
                <div className="community__search">
                  <CustomInput
                    className="community__search-input"
                    id="member-filter"
                    type="text"
                    name="member-filter"
                    placeholder="Search"
                    input={search}
                    icon={<Icon.Search />}
                  />
                  <CustomButton
                    className="community__filter-btn btn btn--dark"
                    onClick={handleFilterBtnClick}
                    type="button"
                    id="community-filter"
                  >
                    <Icon.Filter className="icon" />
                    {isMobile ? <span className="sr-only">Filter</span> : 'Filter'}
                  </CustomButton>
                  {!showFilters ? null : (
                    <div className="community__filters">
                      <CustomSelect
                        id="expertises"
                        name="expertises"
                        placeholder="Expertises"
                        options={expertiseOptions}
                        value={expertises}
                        onChange={handleFilters}
                      />
                      <CustomSelect
                        id="roles"
                        name="roles"
                        placeholder="Roles"
                        options={rolesOptions}
                        value={roles}
                        onChange={handleFilters}
                      />
                      <CustomSelect
                        id="classes"
                        name="classes"
                        placeholder="Classes"
                        options={classesOptions}
                        value={classes}
                        onChange={handleFilters}
                      />
                      <CustomSelect
                        id="industries"
                        name="industries"
                        placeholder="Industries"
                        options={industriesOptions}
                        value={industries}
                        onChange={handleFilters}
                      />
                    </div>
                  )}
                </div>
              </div>
              <ToggleSelect selected={0} items={TABS} onChange={handleTabChange} />
              {!error ? null : <Message text={error.message} status="error" />}
              <div className={`community__lister-list${showMap ? ' hide' : ''}`}>
                {!result?.data ? null : !result.data.items.length ? (
                  <div>No community members found</div>
                ) : (
                  <ul className="community__list gap-md">
                    {result.data.items.map((member: Member) => (
                      <CommunityItem
                        member={member}
                        key={member._id}
                        hotMarker={hotMarker}
                        setHotMarker={setHotMarker}
                      />
                    ))}
                  </ul>
                )}
                {result?.data?.items.length && result?.data.pager?.total > 1 ? (
                  <Pager total={result.data.pager.total} current={+page} />
                ) : null}
              </div>
              {!loading ? null : (
                <ul className="community__list gap-md">
                  {[...Array(12)].map((el, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className="community-item community-item--loader block-loader" key={i} />
                  ))}
                </ul>
              )}
            </div>
            <div className={`community__map${showMap ? '' : ' hide'}`}>
              <CommunityMap
                members={result?.data?.items}
                center={new google.maps.LatLng({ lat: 41, lng: -20 })}
                zoom={2}
                hotMarker={hotMarker}
                setHotMarker={setHotMarker}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Community;

import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IOptions {
  name?: string;
  slug?: string;
}

const useTrackPage = ({ name, slug }: IOptions) => {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track(name || 'Page view', {
      pathname: location.pathname,
      search: location.search || undefined,
      slug,
    });
  }, [location.pathname, location.search, name, slug]);
  return {};
};

export default useTrackPage;

import { Wrapper } from '@googlemaps/react-wrapper';

import Community from '../../Components/Community/Community';
import useTrackPage from '../../core/hooks/useTrackPage';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';

function CommunityPage() {
  useTrackPage({});

  return (
    <Wrapper libraries={['places']} apiKey={GOOGLE_MAPS_API_KEY}>
      <Community />
    </Wrapper>
  );
}

export default CommunityPage;

import mixpanel from 'mixpanel-browser';
import { forwardRef, useCallback } from 'react';

interface IProps {
  type: 'submit' | 'button';
  className: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  onPointerEnter?(): void;
  onKeyDown?(): void;
  onFocus?(): void;
  id?: string;
}

const CustomButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      type,
      loading,
      disabled,
      className,
      children,
      onClick,
      onPointerEnter,
      onKeyDown,
      onFocus,
      id,
    },
    ref,
  ) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        mixpanel.track('Button click', {
          id,
        });
        if (!onClick) return;
        onClick(e);
      },
      [id, onClick],
    );

    return (
      <button
        className={className}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={(e) => handleClick(e)}
        onPointerEnter={onPointerEnter}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        disabled={loading || disabled}
        ref={ref}
        id={id}
      >
        {children}
        {loading && <span className="loader">Loading...</span>}
      </button>
    );
  },
);

export default CustomButton;

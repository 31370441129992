import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import CustomLink from '../../common/CustomLink/CustomLink';
import { ILink } from '../../core/models';
import HubSpotFromModal from '../HubSpotFromModal/HubSpotFromModal';
import './Result.css';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  form?: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  resultItems: {
    title: string;
    desc?: string;
  }[];
  background?: 'grey' | 'blue' | 'red';
  slug: string;
}

function Result({ title, desc, cta, form, resultItems, background, slug }: IProps) {
  const items = useMemo(
    () =>
      resultItems?.map((item) => (
        <div className="result__item flex gap-sm px-sm py-sm" key={item.title}>
          <h3 className="result__item-title h3">{item.title}</h3>
          <p className="result__item-desc body-parge">{item.desc}</p>
        </div>
      )) || [],
    [resultItems],
  );

  return (
    <section
      className={`result section-bg${
        background === 'blue'
          ? ' section-bg--primary'
          : background === 'red'
          ? ' section-bg--secondary'
          : ' section-bg--grey'
      }`}
    >
      <div className="wrap flex gap-lg">
        <div className="result__content gap-lg">
          <h2 className="result__title h2">{title}</h2>
          <div className="result__info flex gap-md">
            {!desc ? null : (
              <ReactMarkdown
                className="result__desc markdown body-large"
                rehypePlugins={[rehypeRaw, senitize]}
                components={{
                  p: React.Fragment,
                }}
              >
                {desc}
              </ReactMarkdown>
            )}
            {!cta ? null : <CustomLink className="result__cta" link={cta} target="_blank" />}
            <HubSpotFromModal form={form} slug={slug} />
          </div>
        </div>
        <div className="result__container gap-md">{items}</div>
      </div>
    </section>
  );
}

export default Result;

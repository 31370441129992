import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import { useDataContext } from '../../../core/context/data.context';
import './UserMenu.css';

function UserMenu() {
  const navigate = useNavigate();
  const {
    data: { generalData },
  } = useDataContext();

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="user-menu">
        <h1 className="user-menu__title h1">Profile</h1>
        <div className="user-menu__container">
          <CustomButton
            type="button"
            className="user-menu__button link"
            onClick={() => navigate('/profile-edit')}
            id="edit-profile"
          >
            Edit profile
            <Icon.Chevron className="icon rotate-minus-90" />
          </CustomButton>
          <CustomButton
            type="button"
            className="user-menu__button link"
            onClick={() => navigate('/security-privacy')}
            id="security-privacy"
          >
            Security & privacy
            <Icon.Chevron className="icon rotate-minus-90" />
          </CustomButton>
        </div>
      </section>
    </>
  );
}

export default UserMenu;

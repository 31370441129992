import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { slugify } from '../../../core/helpers';
import useModal from '../../../core/hooks/useModal';
import { IImage } from '../../../core/models';
import EmbeddedAI from '../../GPT/QuestionCoachGPT';
import './GPTCarouselItem.css';

export interface IGPTCarouselItem {
  title?: string;
  desc?: string;
  img?: IImage;
  gpt?: {
    publicToken?: string;
    appId?: string;
    btnLabel?: string;
  };
  heightestTitle: number | null;
}

const GPTCarouselItem = forwardRef<HTMLHeadingElement, IGPTCarouselItem>(
  ({ title, desc, img, gpt, heightestTitle }: IGPTCarouselItem, ref) => {
    const { width } = useScreenSizeContext();
    const { modal, open, close } = useModal();
    const btnRef = useRef<HTMLButtonElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLHeadingElement>(null);

    const openModal = useCallback(() => {
      if (!btnRef.current) return;
      open(btnRef.current);
    }, [open]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => titleRef.current!, []);

    useEffect(() => {
      if (!descRef.current || !titleRef.current) return;
      const titleHeight = titleRef.current.scrollHeight;
      const heightDif = (heightestTitle || 0) - titleHeight;
      descRef.current.style.marginTop = `${heightDif}px`;
    }, [heightestTitle, width]);

    return (
      <div className="flex gap-sm">
        <div className="gpt-carousel__slide-img-container">
          {!img ? null : (
            <Image className="gpt-carousel__slide-img cover-img" img={img} size="small" />
          )}
        </div>
        <div className="gpt-carousel__slide-content flex gap-sm">
          {!title ? null : (
            <h3 className="h3" ref={titleRef}>
              {' '}
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h3>
          )}
          {!desc ? null : (
            <div ref={descRef}>
              <ReactMarkdown>{desc}</ReactMarkdown>
            </div>
          )}
          {!gpt?.appId || !gpt?.btnLabel ? null : (
            <CustomButton
              className="btn btn--secondary"
              onClick={openModal}
              type="button"
              ref={btnRef}
              id={`${slugify(title || '')}-${gpt?.appId}`}
            >
              {gpt?.btnLabel || 'Open'}
            </CustomButton>
          )}
        </div>
        {!gpt?.appId
          ? null
          : modal({
              children: (
                <>
                  <CustomButton
                    className="modal__close"
                    onClick={close}
                    type="button"
                    id={`${title ? slugify(title) : 'gpt-item'}-modal-close`}
                  >
                    <Icon.Plus className="icon-large" />
                    <span className="sr-only">Close modal</span>
                  </CustomButton>
                  {!gpt?.appId || !gpt.publicToken ? null : (
                    <div className="gpt-carousel__chat-container">
                      <EmbeddedAI publicToken={gpt.publicToken} appId={gpt.appId} />
                    </div>
                  )}
                </>
              ),
            })}
      </div>
    );
  },
);

export default GPTCarouselItem;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import PageNotFound from '../../Pages/PageNotFound/PageNotFound';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomInput from '../../common/CustomInput/CustomInput';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import { Icon } from '../../common/Icon/Icon';
import PageLoader from '../../common/PageLoader/PageLoader';
import { useDataContext } from '../../core/context/data.context';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import { Tag } from '../../core/models';
import ArticleFront from './ArticleFront/ArticleFront';
import ArticleLister from './ArticleLister/ArticleLister';
import './Articles.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function Articles() {
  const { isMobile } = useScreenSizeContext();
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const {
    data: { blog: page, generalData },
    setPage,
  } = useDataContext();
  const body = useMemo(() => page || null, [page]);
  const [initialRender, setInitialRender] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const category = useMemo(() => searchParams.get('category') || '', [searchParams]);
  const searchValue = useMemo(() => searchParams.get('search') || '', [searchParams]);
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  const search = useFormInput(searchValue);
  const { result: tags, doFetch: doTagsFetch } = useFetch(
    `${BASE_URL}/tags/published?per_page=100`,
  );

  useEffect(() => {
    setPage('blog');
  }, [setPage]);

  useEffect(() => {
    doTagsFetch();
    setInitialRender(false);
  }, [doTagsFetch]);

  useEffect(() => {
    if (initialRender) return;
    if (timeout.current) clearTimeout(timeout.current);
    searchParams.delete('page');
    timeout.current = setTimeout(() => {
      if (search.value) {
        searchParams.set('search', search.value);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    }, 500);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.value]);

  const handleCategory = useCallback(
    (val: string) => {
      searchParams.delete('page');
      if (val && val !== category) {
        searchParams.set('category', val);
      } else {
        searchParams.delete('category');
      }
      setSearchParams(searchParams);
    },
    [searchParams, category, setSearchParams],
  );

  const categoryOptions = useMemo(
    () =>
      tags?.data.items?.map((el: Tag) => ({
        label: el.name,
        value: el.name,
      })) || [],
    [tags?.data.items],
  );

  const categoryTags = useMemo(
    () =>
      tags?.data.items?.map((el: Tag) => (
        <CustomButton
          className={`articles__category-btn tag tag--filter${
            el.name === category ? ' tag--active' : ''
          }`}
          type="button"
          key={el._id}
          id={el._id}
          onClick={() => handleCategory(el.name)}
        >
          {el.name}
        </CustomButton>
      )),
    [tags?.data, handleCategory, category],
  );

  return (
    <>
      <Helmet>
        {!page?.seo?.title && !generalData?.seo?.title ? null : (
          <title>{page?.seo?.title || generalData?.seo?.title}</title>
        )}
        {!page?.seo?.canonical ? null : <link rel="canonical" href={page?.seo?.canonical} />}
        {!page?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={page?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!body} />, modalContainer)}
      {!body ? null : body === 'Page not found' ? (
        <PageNotFound />
      ) : (
        <section className="articles">
          <div className="wrap">
            <div className="articles__header flex gap-md">
              <h1 className="articles__title h1">{body.title}</h1>
              {!body.desc ? null : <p className="articles__desc h3">{body.desc}</p>}
              <div className="articles__bar">
                {isMobile ? (
                  <CustomSelect
                    className="articles__category-select"
                    id="category"
                    name="category"
                    placeholder="Category"
                    options={categoryOptions}
                    onChange={(e) => {
                      handleCategory(e.currentTarget.value);
                    }}
                  />
                ) : (
                  <div className="articles__category">
                    <div className="articles__category-nav">{categoryTags}</div>
                  </div>
                )}
                <div className="articles__search">
                  <CustomInput
                    id="search"
                    name="search"
                    type="text"
                    placeholder="Search"
                    input={search}
                    icon={<Icon.Search />}
                  />
                </div>
              </div>
            </div>
          </div>
          {searchValue || category ? (
            <ArticleLister category={category} searchValue={searchValue} />
          ) : (
            <ArticleFront body={body} />
          )}
        </section>
      )}
    </>
  );
}

export default Articles;

import UserMenu from '../../../Components/User/UserMenu/UserMenu';
import useTrackPage from '../../../core/hooks/useTrackPage';

function Overview() {
  useTrackPage({});

  return (
    <div className="user-overview">
      <div className="wrap wrap--xs">
        <UserMenu />
      </div>
    </div>
  );
}

export default Overview;

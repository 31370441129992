import { Helmet } from 'react-helmet-async';

import InviteForm from '../../Components/InviteForm/InviteForm';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useDataContext } from '../../core/context/data.context';
import useTrackPage from '../../core/hooks/useTrackPage';

function Invite() {
  useTrackPage({});
  const {
    data: { generalData },
  } = useDataContext();

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="invite">
        <div className="wrap wrap--xs">
          <PageTitle title="Create your account" />
        </div>
        <div className="wrap wrap--xs">
          <InviteForm />
        </div>
      </div>
    </>
  );
}

export default Invite;

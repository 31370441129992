/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import PageBanner from '../../Components/PageBanner/PageBanner';
import PageBannerSmall from '../../Components/PageBannerSmall/PageBannerSmall';
import PageBuilder from '../../Components/PageBuilder/PageBuilder';
import PageLoader from '../../common/PageLoader/PageLoader';
import { useDataContext } from '../../core/context/data.context';
import useTrackPage from '../../core/hooks/useTrackPage';
import PageNotFound from '../PageNotFound/PageNotFound';

function Page() {
  const { slug } = useParams();
  useTrackPage({ slug });
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const {
    data: { [`${slug}`]: page, generalData },
    setPage,
  } = useDataContext();
  const body = useMemo(() => page || null, [page]);

  useEffect(() => {
    setPage(slug);
  }, [setPage, slug]);

  if (body === 'Page not found') return <PageNotFound />;

  return (
    <div>
      <Helmet>
        {!body?.seo?.title && !generalData?.seo?.title ? null : (
          <title>{body?.seo?.title || generalData?.seo?.title}</title>
        )}
        {!body?.seo?.canonical ? null : <link rel="canonical" href={body?.seo?.canonical} />}
        {!body?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={body?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!body} />, modalContainer)}
      {!body ? null : (
        <>
          {!body.pageBanner ? null : body.pageBanner.type === 'small' ? (
            <PageBannerSmall {...body.pageBanner} slug={slug} />
          ) : (
            <PageBanner {...body.pageBanner} />
          )}
          {!body.pageBuilder ? null : <PageBuilder data={body.pageBuilder} slug={slug || ''} />}
        </>
      )}
    </div>
  );
}

export default Page;

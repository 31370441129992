import { useCallback, useRef } from 'react';

import CustomButton from '../../common/CustomButton/CustomButton';
import { Icon } from '../../common/Icon/Icon';
import useModal from '../../core/hooks/useModal';
import { IForm } from '../../core/models/form';
import HubSpotForm from '../HubSpotForm/HubSpotForm';

interface IProps {
  form?: IForm;
  slug?: string;
}

function HubSpotFromModal({ form, slug }: IProps) {
  if (!form?.btnLabel || !form?.portalId || !form?.formId || !form?.region || !form?.id)
    return null;
  const { modal, open, close } = useModal();
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <>
      <CustomButton
        className={`programs__item-cta btn${
          !form.btnColor ? ' btn--secondary' : ` btn--${form.btnColor}`
        }`}
        type="button"
        onClick={openModal}
        ref={btnRef}
        id={`${slug}-${form.formId}`}
      >
        {form.btnLabel}
      </CustomButton>
      {modal({
        children: (
          <>
            <CustomButton
              className="modal__close"
              onClick={close}
              type="button"
              id={`${slug}-${form.formId}-modal-close`}
            >
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Close modal</span>
            </CustomButton>
            <HubSpotForm
              portalId={form.portalId}
              formId={form.formId}
              region={form.region}
              id={form.id}
            />
          </>
        ),
        keepMounted: true,
      })}
    </>
  );
}

export default HubSpotFromModal;

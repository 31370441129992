import Articles from '../../Components/Articles/Articles';
import useTrackPage from '../../core/hooks/useTrackPage';

function Blog() {
  useTrackPage({});

  return <Articles />;
}

export default Blog;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import LinkRenderer from '../../common/LinkRenderer/LinkRenderer';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import './Markdown.css';

interface IProps {
  body: any;
}

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function Markdown({ body }: IProps) {
  const markdown = useMemo(
    () =>
      !body
        ? null
        : body
            .replaceAll('@@SERVER_BASE_URL@@', BASE_URL)
            .replaceAll('@@CLIENT_BASE_URL@@', `${encodeURIComponent(APP_BASE_URL)}/case`),
    [body],
  );

  if (!body) return null;

  return (
    <section className="section markdown">
      <div className="wrap">
        <ReactMarkdown
          className="markdown__body markdown"
          components={{
            a: LinkRenderer,
            video: VideoPlayer,
          }}
          rehypePlugins={[rehypeRaw, senitize]}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    </section>
  );
}

export default Markdown;

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as H } from '../../assets/h.svg';
import { ReactComponent as K } from '../../assets/k.svg';
import { ReactComponent as N } from '../../assets/n.svg';
import { ReactComponent as Symbol } from '../../assets/symbol.svg';
import { ReactComponent as T } from '../../assets/t.svg';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomDropdown from '../../common/Dropdown/CustomDropdown';
import { Icon } from '../../common/Icon/Icon';
import { useDataContext } from '../../core/context/data.context';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { useThemeContext } from '../../core/context/theme.context';
import { useUserContext } from '../../core/context/user.context';
import useModal from '../../core/hooks/useModal';
import { INavigation } from '../../core/models';
import UserDropdown from '../User/UserDropdown/UserDropdown';
import './Header.css';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: {
      generalData: { navigation },
    },
  }: { data: { generalData: INavigation } } = useDataContext();
  const { isScrolled, setIsScrolled, hideLogo } = useThemeContext();
  const { user } = useUserContext();
  const { isMobile } = useScreenSizeContext();
  const burgerRef = useRef(null);
  const { modal, open, close, isOpen } = useModal();

  const openNav = useCallback(() => {
    if (!burgerRef.current) return;
    open(burgerRef.current);
  }, [open]);

  useEffect(() => {
    if (document.body.style.inset) {
      document.body.style.inset = '0';
      setIsScrolled(false);
    }
    close();
  }, [location, isMobile, close, setIsScrolled]);

  const navList = useMemo(
    () => (
      <ul className="header__nav-list">
        {!navigation?.navItems?.length
          ? null
          : navigation.navItems.map((link) =>
              link.hasSubMenu ? (
                <li className="header__nav-item" key={link.label}>
                  {!link.href ? (
                    <CustomDropdown
                      isLink
                      selected={location.pathname}
                      items={link.subItems?.map((item) => ({
                        value: item.href,
                        label: item.label,
                      }))}
                      handleChange={(
                        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
                        val: string,
                      ) => {
                        navigate(val);
                      }}
                    >
                      <CustomButton
                        type="button"
                        className={`header__nav-link${
                          link.subItems?.map((item) => item.href).includes(location.pathname)
                            ? ' active'
                            : ''
                        }`}
                      >
                        {link.label}
                      </CustomButton>
                    </CustomDropdown>
                  ) : (
                    <CustomDropdown
                      selected={location.pathname}
                      items={link.subItems?.map((item) => ({
                        value: item.href,
                        label: item.label,
                      }))}
                      isLink
                      handleChange={(
                        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
                        val: string,
                      ) => {
                        navigate(val);
                      }}
                    >
                      <Link
                        to={link.href}
                        className={`header__nav-link${
                          link.href === location.pathname ? ' active' : ''
                        }`}
                      >
                        {link.label}
                      </Link>
                    </CustomDropdown>
                  )}
                </li>
              ) : (
                <li className="header__nav-item" key={link.label}>
                  <NavLink
                    className={({ isActive }) => `header__nav-link${isActive ? ' active' : ''}`}
                    to={link.href || ''}
                    replace={isOpen}
                  >
                    {link.label}
                  </NavLink>
                </li>
              ),
            )}
        {user.token ? (
          <li className="header__nav-item">
            <UserDropdown />
          </li>
        ) : !navigation || navigation.hideLogin ? null : (
          <li className="header__nav-item">
            <NavLink
              className={({ isActive }) => `header__nav-link${isActive ? ' active' : ''}`}
              to="/login"
            >
              Log in
            </NavLink>
          </li>
        )}
      </ul>
    ),
    [navigation, user.token, location.pathname, isOpen, navigate],
  );

  return (
    <header className={`header${isScrolled ? ' scrolled' : ''}`}>
      <div className="wrap">
        <nav className="header__nav">
          {hideLogo && !isScrolled ? null : (
            <Link className="header__logo" to="/">
              <span className="sr-only">Home page</span>
              <Symbol className="header__logo-symbol" />
              {isMobile ? null : (
                <span className="header__logo-mask">
                  <T className="header__logo-letter" />
                  <H className="header__logo-letter" />
                  <N className="header__logo-letter" />
                  <K className="header__logo-letter" />
                </span>
              )}
            </Link>
          )}
          {isMobile ? (
            <CustomButton
              className="header__burger link"
              type="button"
              onClick={openNav}
              ref={burgerRef}
              id="header-menu"
            >
              Menu
            </CustomButton>
          ) : (
            navList
          )}
        </nav>
      </div>
      {modal({
        children: (
          <div className="header__mobile-nav">
            <CustomButton
              className="header__close"
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              id="header-menu-close"
            >
              <span className="sr-only">Close menu</span>
              <Icon.Plus className="icon rotate-45" />
            </CustomButton>
            <Link className="header__logo header__logo--mobile" to="/" replace>
              <span className="sr-only">Home page</span>
              <Symbol className="header__logo-symbol" />
            </Link>
            {navList}
          </div>
        ),
        side: 'right',
        preventNavigate: true,
      })}
    </header>
  );
}

export default Header;

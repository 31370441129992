import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../../../Components/User/LoginForm/LoginForm';
import { useDataContext } from '../../../core/context/data.context';
import { useUserContext } from '../../../core/context/user.context';
import useTrackPage from '../../../core/hooks/useTrackPage';

function Login() {
  useTrackPage({});
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    data: { generalData },
  } = useDataContext();

  useEffect(() => {
    if (user.email) navigate('/');
  }, [navigate, user]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <div className="login">
        <div className="wrap wrap--xs">
          <LoginForm />
        </div>
      </div>
    </>
  );
}

export default Login;

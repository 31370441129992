import { Link } from 'react-router-dom';

import useTrackPage from '../../core/hooks/useTrackPage';
import './PageNotFound.css';

function PageNotFound() {
  useTrackPage({ name: 'Page not found' });

  return (
    <section className="not-found">
      <div className="wrap">
        <h1 className="not-found__title h2">Oops, page not found</h1>
        <Link className="btn btn--dark" to="/">
          Go to homepage
        </Link>
      </div>
    </section>
  );
}

export default PageNotFound;

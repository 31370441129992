/* eslint-disable react/no-danger */
import { Fragment, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import senitize from 'rehype-sanitize';

import ArticleBanner from '../../Components/Articles/ArticleBanner/ArticleBanner';
import LinkRenderer from '../../common/LinkRenderer/LinkRenderer';
import PageLoader from '../../common/PageLoader/PageLoader';
import PageTitle from '../../common/PageTitle/PageTitle';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import { useDataContext } from '../../core/context/data.context';
import { ToBool } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import useTrackPage from '../../core/hooks/useTrackPage';
import { Member, Tag } from '../../core/models';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Article.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
const SHOW_ORIGINAL_LINK = ToBool(process.env.REACT_APP_SHOW_ORIGINAL_ARTICLE_LINK);

function Article() {
  const {
    data: { generalData },
  } = useDataContext();
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const { slug } = useParams();
  useTrackPage({ slug });
  const {
    result: article,
    doFetch,
    loading,
    error,
  } = useFetch(`${BASE_URL}/article?slug=${slug}&populate=tags&populate=img&populate=authors`);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  const markdown = useMemo(
    () =>
      !article?.data?.body
        ? null
        : article.data.body
            .replaceAll('@@SERVER_BASE_URL@@', BASE_URL)
            .replaceAll('@@BLOG_BASE_URL@@', `${encodeURIComponent(APP_BASE_URL)}/blog`),
    [article?.data?.body],
  );

  if (error) return <PageNotFound />;

  return (
    <>
      <Helmet>
        {!article?.data?.seo?.title && article?.data?.title && !generalData?.seo?.title ? null : (
          <title>
            {article?.data?.seo?.title || article?.data?.title || generalData?.seo?.title}
          </title>
        )}
        <link rel="canonical" href={`${APP_BASE_URL}/blog/${slug}`} />
        {!article?.data?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={article?.data?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!article} />, modalContainer)}
      <article className="article">
        {!loading ? null : <div className="wrap fade-in">Loading...</div>}
        {!article?.data._id ? null : (
          <>
            <div className="wrap">
              <PageTitle
                title={article.data.title}
                backLabel={window.history.length > 1 ? 'Back' : 'The Pulse'}
                backPath={window.history.length > 1 ? -1 : '/blog'}
                info={
                  <div className="article__header-info">
                    <div className="article__header-authors">
                      {article.data.authors?.map((author: Member, i: number) => (
                        <Fragment key={author._id}>
                          {i === 0 ? null : ', '}
                          {author.slug ? (
                            <a href={`/community/people/${author.slug}`}>
                              {author.firstname} {author.lastname}
                            </a>
                          ) : (
                            `${author.firstname} ${author.lastname}`
                          )}
                        </Fragment>
                      ))}
                    </div>
                    {!article.data.tags.length ? null : (
                      <div className="article__header-tags">
                        {article.data.tags.map((tag: Tag) => (
                          <div className="tag" key={tag._id}>
                            {tag.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            <section className="article__content">
              <ArticleBanner text={article.data.summary} img={article.data.img} />
              <div className="wrap">
                {!SHOW_ORIGINAL_LINK || !article.data.original_link ? null : (
                  <div className="tag">
                    <span className="body-regular">Original link:</span>{' '}
                    <a
                      className="link"
                      href={article.data.original_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {article.data.original_link}
                    </a>
                  </div>
                )}
                {markdown ? (
                  <ReactMarkdown
                    className="article__body py-lg markdown"
                    components={{
                      a: LinkRenderer,
                      video: VideoPlayer,
                    }}
                    rehypePlugins={[rehypeRaw, senitize]}
                  >
                    {markdown}
                  </ReactMarkdown>
                ) : null}
              </div>
            </section>
          </>
        )}
      </article>
    </>
  );
}

export default Article;
